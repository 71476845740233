@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

#navbar{
    background: #111725;
    box-shadow: -1px 4px 9px 1px #342c2c;
}
.nav-item a{
    font-size: 18px;
    color: #fdcea6;
    font-family: 'Josefin Sans', cursive;
}
.have-sub:hover .submenu {
    display: block;
}
.submenu{
    list-style: none;
    /* display: none; */
    position: absolute;
    width: 280px;
    display: none;
}
.submenu li a{
    color: white;
    padding: 10px;
}
